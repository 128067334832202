import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';

const DetailsLayoutAnchor = ({
  label,
  anchorTag,
  anchorBaseUrl,
  removeTopSpacer,
  actions
}) => {
  return (
    <>
      {!removeTopSpacer && <Spacer size="xl" />}
      <Box id={anchorTag} />
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center" gap="xs">
          {anchorBaseUrl && (
            <Box
              renderAs={anchorBaseUrl ? 'a' : 'div'}
              href={anchorBaseUrl ? `${anchorBaseUrl}#${anchorTag}` : null}
              className="details-layout-anchor"
            >
              <IconFontAwesome name="hashtag" />
            </Box>
          )}
          <TextH2>{label}</TextH2>
        </FlexBox>
        {actions && <FlexBox>{actions}</FlexBox>}
      </FlexBox>
      <Spacer size="xs" />
    </>
  );
};

DetailsLayoutAnchor.propTypes = {
  label: PropTypes.string,
  anchorTag: PropTypes.string,
  anchorBaseUrl: PropTypes.string,
  removeTopSpacer: PropTypes.bool,
  actions: PropTypes.node
};

DetailsLayoutAnchor.defaultProps = {
  label: 'Anchor',
  removeTopSpacer: false
};

export default DetailsLayoutAnchor;
