import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Box, InputSelect, SideTabBar } from '@directsoftware/ui-kit-web-admin';
import classnames from 'classnames';
import { checkPermissions } from 'sharedNav/helpers';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../../shared/hooks';
import SideTabBarWaypoint from '../../../components/side-tab-bar-waypoint';
import { selectReservationsDetailsState } from '../../../redux/slices/reservations';

const tabs = [
  {
    label: 'Reservation Information',
    hash: 'reservationInfo',
    checkPermissionTag: 'usePermissionsHelper',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Payment Line Items',
    hash: 'reservationPaymentLineItems',
    checkPermissionTag: 'usePermissionsHelper',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Listing Information',
    hash: 'reservationListingInfo',
    checkPermissionTag: 'usePermissionsHelper',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Customer Information',
    hash: 'reservationCustomerInfo',
    checkPermissionTag: 'usePermissionsHelperAndCustomer',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Verification Information',
    hash: 'reservationVerificationInfo',
    checkPermissionTag: 'verificationInfo',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Recorded Balance',
    hash: 'reservationRecordedBalance',
    checkPermissionTag: 'usePermissionsHelper',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Payout Information',
    hash: 'reservationPayoutInfo',
    checkPermissionTag: 'payoutInfo',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Reservation Information',
    hash: 'reservationBookingInfoPo',
    checkPermissionTag: 'bookingInfoOwnerFriend',
    permissions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Payment Line Items',
    hash: 'reservationLineItemsPo',
    checkPermissionTag: 'bookingLineItemsPo',
    permissions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Listing Information',
    hash: 'reservationListingInfoPo',
    checkPermissionTag: 'usePermissionsHelper',
    permissions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Customer Information',
    hash: 'reservationCustomerInfoPo',
    checkPermissionTag: 'usePermissionsHelperAndCustomer',
    permissions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Payout Information',
    hash: 'reservationPayoutInfoPo',
    checkPermissionTag: 'reservationPayoutInfoPo',
    permissions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'Payments & Payment Methods',
    hash: 'reservationPaymentsAndPaymentMethods',
    checkPermissionTag: 'payoutInfo',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  },
  {
    label: 'People & Admin',
    hash: 'reservationPeopleAndAdmin',
    checkPermissionTag: 'usePermissionsHelper',
    permissions: 'view_reservations',
    restrictions: [
      'reservations_restricted_line_items',
      'show_no_financial_info'
    ]
  }
];

const ReservationsOverviewSideTabBar = ({
  allProps,
  customer,
  user,
  booking,
  testRef
}) => {
  const { isMobile } = useDetectMobile();
  const { isLoading } = useSelector(selectReservationsDetailsState);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [activeHash, setActiveHash] = useState('');
  const [sideTabs, setSideTabs] = useState([]);
  const [makeFixed, setMakeFixed] = useState(false);
  const [hashFromUrl, setHashFromUrl] = useState(false);
  const [initialHash, setInitialHash] = useState(null);
  const ownerOrFriendBooking =
    booking.booking_type === 'owner' || booking.booking_type === 'friend';

  const mobileScroll = hash => {
    const id = hash;
    const yOffset = isMobile ? -132 : -208;
    const element = document.getElementById(id);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      if (isMobile) window.scrollTo({ top: y, behavior: 'smooth' });
      else testRef.current.scroll({ top: y, behavior: 'smooth' });
    }
  };

  const checkTabPermissions = (tag, item) => {
    switch (tag) {
      case 'reservationPayoutInfoPo':
        return (
          checkPermissions(user, item.permissions, item.restrictions) &&
          !ownerOrFriendBooking &&
          !user.permissions.show_no_financial_info
        );
      case 'bookingLineItemsPo':
        return (
          checkPermissions(user, item.permissions, item.restrictions) &&
          !ownerOrFriendBooking &&
          !user.permissions.show_no_financial_info
        );
      case 'bookingInfoOwnerFriend':
        return (
          checkPermissions(user, item.permissions, item.restrictions) &&
          ownerOrFriendBooking
        );
      case 'payoutInfo':
        return (
          checkPermissions(user, item.permissions, item.restrictions) &&
          !allProps.vehicle_booking &&
          (parseFloat(booking.price_total) > 0.0 || user.admin)
        );
      case 'verificationInfo':
        return (
          checkPermissions(user, item.permissions, item.restrictions) &&
          customer &&
          !ownerOrFriendBooking &&
          (allProps?.standard_contracts?.length > 0 ||
            allProps?.signature_url ||
            allProps?.photo_id_url)
        );
      case 'usePermissionsHelperAndCustomer':
        return (
          checkPermissions(user, item.permissions, item.restrictions) &&
          customer
        );
      case 'usePermissionsHelper':
        return checkPermissions(user, item.permissions, item.restrictions);
      default:
        return true;
    }
  };

  useEffect(
    () => {
      if (!isLoading && hashFromUrl) {
        setTimeout(() => {
          mobileScroll(initialHash);
        }, 250);
      }
    },
    [isLoading, hashFromUrl]
  );

  useEffect(() => {
    const availableTabs = tabs.filter(tab =>
      checkTabPermissions(tab.checkPermissionTag, tab)
    );
    let hash = availableTabs[0].hash;
    setSideTabs(availableTabs);

    if (window.location.hash) {
      const urlHash = window.location.hash.replace('#', '');
      const tagArray = availableTabs.map(tab => tab.hash);

      // check if window hash is in available tabs
      if (tagArray.includes(urlHash)) {
        hash = urlHash;
        setHashFromUrl(true);
      }
    }

    // set active hash
    setInitialHash(hash);
    setActiveHash(hash);
  }, []);

  return isMobile ? (
    <>
      <Waypoint
        onEnter={() => {
          setMakeFixed(false);
        }}
        onLeave={() => {
          setMakeFixed(true);
        }}
        topOffset={112}
      />
      <Box
        flex="1"
        className={classnames('details-layout-sidebar-mobile', {
          'make-fixed': makeFixed
        })}
        padding="s"
      >
        <InputSelect
          options={sideTabs.map(option => {
            return {
              label: option.label,
              value: option.hash
            };
          })}
          onChange={option => {
            mobileScroll(option.value);
            setActiveHash(option.value);
          }}
          value={tabs.filter(res => res.hash === activeHash)[0]}
          inputWidth="auto"
          removeBottomSpacer
          isSearchable={false}
        />
      </Box>
      {makeFixed && <Box style={{ height: 70 }} />}
    </>
  ) : (
    <SideTabBarWaypoint indicatorPosition={indicatorPosition}>
      {sideTabs.map((tab, idx) => (
        <SideTabBar.Tab
          key={`side-tab-${idx}`}
          linkType="router-hash-link"
          setIndicatorPosition={position => setIndicatorPosition(position)}
          href={`#${tab.hash}`}
          onClick={() => setActiveHash(tab.hash)}
          isActive={activeHash === tab.hash}
        >
          {tab.label}
        </SideTabBar.Tab>
      ))}
    </SideTabBarWaypoint>
  );
};

ReservationsOverviewSideTabBar.propTypes = {};

export default ReservationsOverviewSideTabBar;
