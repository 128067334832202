import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Box, InputSelect, SideTabBar } from '@directsoftware/ui-kit-web-admin';
import classnames from 'classnames';
import SideTabBarWaypoint from '../../../components/side-tab-bar-waypoint';
import { useDetectMobile } from '../../../../shared/hooks';

const SideTabAnchorsCore = ({ sideTabs }) => {
  const { isMobile } = useDetectMobile();
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [activeHash, setActiveHash] = useState('');
  const [makeFixed, setMakeFixed] = useState(false);
  const setHash = () => {
    setActiveHash(sideTabs[0].hash);
  };

  const mobileScroll = hash => {
    const id = hash;
    const yOffset = -120;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(
    () => {
      setHash();
    },
    [sideTabs]
  );

  useEffect(() => {
    setHash();
    if (window.location.hash) {
      const urlHash = window.location.hash.replace('#', '');
      setActiveHash(urlHash);
      if (isMobile) mobileScroll(urlHash);
    }
  }, []);

  return isMobile ? (
    <>
      <Waypoint
        onEnter={() => {
          setMakeFixed(false);
        }}
        onLeave={() => {
          setMakeFixed(true);
        }}
        topOffset={112}
      />
      <Box
        flex="1"
        className={classnames('details-layout-sidebar-mobile', {
          'make-fixed': makeFixed
        })}
        padding="s"
      >
        <InputSelect
          options={sideTabs.map(option => {
            return {
              label: option.label,
              value: option.hash
            };
          })}
          onChange={option => {
            mobileScroll(option.value);
            setActiveHash(option.value);
          }}
          value={sideTabs.filter(res => res.hash === activeHash)[0]}
          inputWidth="auto"
          removeBottomSpacer
          isSearchable={false}
        />
      </Box>
      {makeFixed && <Box style={{ height: 70 }} />}
    </>
  ) : (
    <SideTabBarWaypoint indicatorPosition={indicatorPosition}>
      {sideTabs.map((tab, idx) => (
        <SideTabBar.Tab
          key={`side-tab-${idx}`}
          linkType="router-hash-link"
          setIndicatorPosition={position => setIndicatorPosition(position)}
          href={`#${tab.hash}`}
          onClick={() => setActiveHash(tab.hash)}
          isActive={activeHash === tab.hash}
        >
          {tab.label}
        </SideTabBar.Tab>
      ))}
    </SideTabBarWaypoint>
  );
};

export default SideTabAnchorsCore;
