import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormField,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import UnitListingService from 'adminApi/UnitListingService';
import displayError from 'sharedComponents/ErrorDisplay';
import { useDispatch, useSelector } from 'react-redux';
import isNull from 'lodash/isNull';
import first from 'lodash/first';
import PermissionComponent from 'PermissionComponent';
import { ajaxDataWithAuthorization } from 'apiClient';
import UnitService from 'adminApi/UnitService';
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import { useDetectMobile } from 'sharedHooks';
import { getVehicleAvailability } from 'adminApi/VehicleService';
import { QuoteService } from 'adminApi';
import {
  updateNewBooking,
  selectBookingStepOneState
} from '../../redux/slices/createBooking';

const restrictedTypeOptions = [
  { value: 'owner', label: 'Owner Block' },
  { value: 'friend', label: 'Friend of Owner' }
];

const readTypeOptions = [
  { value: 'guest', label: 'Guest Reservation' },
  { value: 'owner', label: 'Owner Block' },
  { value: 'maintenance', label: 'Maintenance Block' },
  { value: 'admin', label: 'Admin Block' },
  { value: 'friend', label: 'Friend of Owner' },
  { value: 'comp', label: 'Comped Guest' }
];

const statusOptions = [
  { value: 'true', label: 'Confirmed Booking' },
  { value: 'false', label: 'Booking Request' }
];

const BookingStepOne = ({
  brands,
  organization,
  user,
  activateAdvanceButton,
  onDatesChange,
  handleContentIsScrollable
}) => {
  const { isMobile, isHD, isSuperHD } = useDetectMobile();
  const dispatch = useDispatch();
  const booking = useSelector(selectBookingStepOneState);
  const brandsOptions = brands.map(brand => ({
    label: brand.name,
    value: brand.id
  }));
  const listingsOptions = booking.listings.map(listing => ({
    label: listing.name,
    value: listing.id,
    unit_code: listing.unit_code
  }));
  const [availabilityCalendar, setAvailabilityCalendar] = useState(null);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingEndDate, setBookingEndDate] = useState(null);

  const defaultBrand = () => {
    const possibleDefaultBrand = brands.filter(b => b.default);
    if (possibleDefaultBrand.length > 0) {
      return first(possibleDefaultBrand).id;
    } else {
      return first(brands).id;
    }
  };

  const sortFunction = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const populateListings = brandId => {
    UnitListingService.list(organization.id, brandId)
      .then(response => {
        dispatch(
          updateNewBooking({
            brand_id: brandId,
            listings: response.sort(sortFunction)
          })
        );
      })
      .catch(err => displayError({ message: 'Error loading listings', err }));
  };

  const populateOwnerListings = () => populateListings(defaultBrand());

  const listingsChange = listing => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'GET',
        url: `/api/${organization.id}/listings/${listing.value}/owner`
      })
    )
      .done(data => {
        const selectedListing = booking.listings.filter(
          l => l.id === listing.value
        )[0];
        const newBookableId = selectedListing.vehicle_id
          ? selectedListing.vehicle_id
          : selectedListing.unit_id;
        const vehicleBooking = !!selectedListing.vehicle_id;
        dispatch(
          updateNewBooking({
            listing_id: listing.value,
            listing_name: listing.label,
            bookable_id: newBookableId,
            owner: data.owner[0],
            post_checkout_cleaning_enabled: data.post_checkout_cleaning_enabled,
            vehicle_booking: vehicleBooking
          })
        );
      })
      .fail(jqXhr => {
        console.warn(jqXhr);
      });
  };

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else if (!isNull(option.data.unit_code)) {
      if (
        option.data.unit_code.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const fetchAvailability = () => {
    if (booking.vehicle_booking) {
      getVehicleAvailability({
        orgId: organization.id,
        vehicleId: booking.bookable_id
      }).then(res => setAvailabilityCalendar(res.cx_availability_calendar));
    } else {
      UnitService.getUnitAvailability(
        organization.id,
        booking.bookable_id
      ).then(response => {
        setAvailabilityCalendar(response.availability_calendar);
      });
    }
  };

  const bookingChange = bookingType => {
    if (bookingType === 'owner' && booking.owner) {
      dispatch(
        updateNewBooking({
          customer_name: booking.owner.name,
          customer_email: booking.owner.email,
          customer_telephone: booking.owner.telephone,
          confirmed: true
        })
      );
    } else if (bookingType === 'guest' || bookingType === 'friend') {
      dispatch(
        updateNewBooking({
          confirmed: bookingType === 'friend' ? true : booking.confirmed
        })
      );
    }
    fetchAvailability();
  };

  const datesChange = (startDate, endDate) => {
    setBookingStartDate(startDate);
    setBookingEndDate(endDate);
    QuoteService.availabilityCheck(
      organization.id,
      booking.bookable_id,
      startDate,
      endDate,
      organization.organization_type === 'rv_fleet'
    )
      .then(response => {
        onDatesChange(startDate, endDate);
      })
      .catch(err => {
        activateAdvanceButton(true);
        displayError({ message: 'Error creating quote', err });
      });
  };

  useEffect(
    () => {
      if (user.role_name === 'property_contact') populateOwnerListings();
    },
    [user]
  );

  useEffect(
    () => {
      if (
        booking.listing_id !== '' &&
        booking.brand_id !== '' &&
        booking.booking_type !== '' &&
        (booking.booking_type === 'guest' || booking.booking_range)
      ) {
        activateAdvanceButton(false);
      } else {
        activateAdvanceButton(true);
      }
    },
    [booking]
  );

  useEffect(() => {
    handleContentIsScrollable(true);
  }, []);

  return (
    <Box
      className="createReservationModal__contentWrapper"
      paddingTop="s"
      paddingBottom="m"
    >
      <TextH2>Listing Info</TextH2>
      <Spacer />
      {user.role_name !== 'property_contact' && (
        <FormField
          labelText="Which brand/website is this for?"
          labelHtmlFor="website-select"
          inputType="select"
          inputProps={{
            options: brandsOptions,
            isSearchable: true,
            onChange: selectedOption => populateListings(selectedOption.value),
            value: brandsOptions.filter(
              brand => brand.value === booking.brand_id
            )
          }}
        />
      )}
      <FormField
        labelText="Which listing are you booking?"
        labelHtmlFor="unit-select"
        inputType="select"
        inputProps={{
          options: listingsOptions,
          placeholder: booking.listings.length === 0 ? '-' : 'Select...',
          isSearchable: true,
          isDisabled: booking.listings.length === 0,
          onChange: selectedOption => listingsChange(selectedOption),
          getOptionLabel: option =>
            option.unit_code
              ? `${option.label} - ${option.unit_code}`
              : option.label,
          filterOptions: customFilter,
          value: listingsOptions.filter(
            listing => listing.value === booking.listing_id
          )
        }}
      />
      <PermissionComponent user={user} permission="view_reservations">
        <FormField
          labelText="What type of booking is this?"
          labelHtmlFor="type-select"
          inputType="select"
          inputProps={{
            options:
              user.role_name === 'property_contact'
                ? restrictedTypeOptions
                : readTypeOptions,
            isDisabled: booking.listing_id === '',
            placeholder: booking.listing_id === '' ? '-' : 'Select...',
            onChange: selectedOption => {
              const bookingType = selectedOption.value;
              dispatch(
                updateNewBooking({
                  booking_type: bookingType,
                  booking_type_name: selectedOption.label
                })
              );
              bookingChange(bookingType);
            },
            value: readTypeOptions.filter(
              option => option.value === booking.booking_type
            ),
            inputWidth: 'm'
          }}
        />
      </PermissionComponent>
      {booking.booking_type && (
        <>
          {booking.booking_type === 'guest' ? (
            <FormField
              labelText="What's this booking's status?"
              labelHtmlFor="status-select"
              inputType="select"
              inputProps={{
                options: statusOptions,
                searchable: false,
                value: booking.confirmed ? statusOptions[0] : statusOptions[1],
                onChange: selectedOption => {
                  dispatch(
                    updateNewBooking({
                      confirmed: selectedOption.value === 'true'
                    })
                  );
                },
                inputWidth: 'm'
              }}
            />
          ) : (
            <>
              {booking.bookable_id && availabilityCalendar && (
                <WidgetDatePicker
                  bookingCalendar={availabilityCalendar}
                  label="Booking Range"
                  organizationID={organization.id}
                  unitID={booking.listing_id}
                  startDate={bookingStartDate}
                  endDate={bookingEndDate}
                  initialStartDate={bookingStartDate}
                  initialEndDate={bookingEndDate}
                  onDatesChange={({ startDate, endDate }) => {
                    datesChange(startDate, endDate);
                  }}
                  numberOfMonths={2}
                  readOnly
                  removeOldStyling
                  openDirection={isSuperHD ? 'down' : 'up'}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  withFullScreenPortal={!isHD}
                  onPickerFocused={value => {
                    handleContentIsScrollable(!value);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

BookingStepOne.propTypes = {
  brands: PropTypes.array,
  organization: PropTypes.object,
  user: PropTypes.object,
  activateAdvanceButton: PropTypes.func,
  onDatesChange: PropTypes.func
};

BookingStepOne.defaultProps = {};

export default BookingStepOne;
