import React from 'react';
import styled from 'styled-components';
import Sidebar from 'react-sidebar';

import CustomerInfo from './CustomerInfo';
import Nav from './Nav';
import SectionsRouter from './Sections/SectionsRouter';

/* React Context */
import { CustomerContext } from 'adminContexts';

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fafafa;
  width: 510px;
  max-width: 100vw;
`;

const sidebarStyles = {
  background: 'white',
  display: 'flex',
  justifyContent: 'center'
};

const rootStyles = open => {
  return {
    left: open ? 0 : '100vw',
    height: '100vh',
    minHeight: '100vh',
    zIndex: 200,
    position: 'fixed'
  };
};

const SidebarContents = state => {
  // Sidebar should only be open if there is a valid customer
  // in the provider state
  if (!state.sidebarOpen) return null;

  return (
    <React.Fragment>
      <CustomerInfo {...state} />
      <Nav {...state} />
      <SectionsRouter {...state} />
    </React.Fragment>
  );
};

const CustomerSidebar = () => (
  <CustomerContext.Consumer>
    {state => {
      return (
        <Sidebar
          open={state.sidebarOpen}
          onSetOpen={state.onSetSidebarOpen}
          pullRight
          styles={{
            sidebar: sidebarStyles,
            root: rootStyles(state.sidebarOpen)
          }}
          touch={false}
          sidebar={
            <SidebarContainer>{SidebarContents(state)}</SidebarContainer>
          }
        >
          {/*
            This is simply to get rid of annoying warning that the Sidebar
            Component requires children.
          */}
          <div style={{ display: 'none' }} />
        </Sidebar>
      );
    }}
  </CustomerContext.Consumer>
);

export default CustomerSidebar;
